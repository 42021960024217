import React, { useState } from "react";
import { Col, Row } from "antd";
import citiustechLogo from "../images/citiustech-logo.svg";

const ChatFormBox = ({setShowForm, setShowThankMsg, setMessage, onSend, disable, value}) => {
    // State to manage form inputs
    const [formData, setFormData] = useState({
        email: value || ""
    });

    // State to manage form errors
    const [errors, setErrors] = useState({});
console.log(value, disable)
    // Input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setMessage(e)
    };

    // Form validation function
    const validateField = (name, value) => {
        let error = "";
        if (name === "email") {
            if (!value.trim()) {
                error = "Email is required";
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                error = "Email is invalid";
            }
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    // Validate all fields at once (for form submit)
    const validateForm = () => {
        let formErrors = {};
        if (!formData.email.trim()) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email is invalid";
        }
        return formErrors;
    };

    // Form submit handler
    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            console.log("Form submitted successfully", formData);
            onSend()
            // Perform further actions (e.g., send data to server)
        } else {
            console.log("Form has errors");
        }
    };

    // Handle onBlur event for real-time validation
    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    return (
        <Col className="open-ai-chat-item mb-2">
            <Row className="chat-item-container">
                <Col style={{ width: "34px" }}>
                    {/* <img src={citiustechLogo} alt="User" style={{ width: "34px" }} /> */}
                </Col>

                <Col className="chat-details-container">
                    <form onSubmit={handleSubmit}>
                        <Col className="chat-details-data-form">
                            <Col>
                                <h4>Please share your email</h4>
                                <div className="form-field-cb">
                                    <input
                                        className={`c-chat-input ${errors.email ? "error":""}`}
                                        name="email"
                                        placeholder="Enter your email*"
                                        value={formData.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        readOnly={disable}
                                    />

                                </div>
                                <div className="form-field-cb">
                                    <p>
                                        *By submitting your personal information to Envigo, you
                                        are agreeing to Envigo's{" "}
                                        <a
                                            href="https://www.envigo.co.uk/privacy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>{" "}
                                        on how your information may be used.
                                    </p>
                                </div>

                                <div className="chat-c-cb-m form-field-cb">
                                    <button disabled={disable} type="submit" className="custom-button-container" style={{backgroundColor: disable && "gray"}}>
                                        Submit
                                    </button>
                                </div>
                            </Col>
                        </Col>
                    </form>
                </Col>
            </Row>
        </Col>
    );
};

export default React.memo(ChatFormBox);
