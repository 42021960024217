import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
    Row,
  Space,
  Input,
    Table,
    Switch,
  Button
} from "antd";

import {
    CheckOutlined,
    CloseOutlined,
    DownloadOutlined,
    QuestionCircleOutlined,
    DeploymentUnitOutlined,
    PlusCircleOutlined,
  RocketOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import MyTaskFilterDate from "../components/MyTaskFilter";
import constants from "../utils/constants";
import "../scss/botListing.scss";
import CardItem from "../components/cardItem/CardItem";
import StartNewWebScrapper from "../utils/StartNewWebScrapper";
import axios from "axios";
import appUrl from "../config/appUrl";
import {showNotification} from "../utils/commonFunctions";
import {useNavigate} from "react-router-dom";
import routes from "../routes_mapper";
const { FILTER_TYPE_DATE } = constants;
const defaultFilterType = FILTER_TYPE_DATE?.All?._id;

const WebScrapper = () => {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [showStartNew, setStartNew] = useState(false);
    const [states, setStates] = useState({
    search: "",
    ids: [],
    filterType: defaultFilterType,
    modelOpen: false,
    data: [],
    start_date: "",
    end_date: "",
    });
    const [dashboardData, setDashboardData] = useState({})

    useEffect(() => {
        dashboardApiCall()
    }, []);

    const dashboardApiCall = ()=> {
        axios.get(appUrl.WEB_SCRAPPER).then(response=>{
            // setDashboardData(response?.data?.data);
            setFilteredData(response?.data?.data)
        }).catch(err=>{
            showNotification("error", err.message || "Failed to fetch dashboard")
        })
    }





    const handleStartScrappingModal = useCallback((show = false) => {
    show = typeof show === "boolean" && show;
    setStartNew(show);
  }, []);


  const handleFileDownload = async (id) => {
    try {
        const response = await axios.get(appUrl.WEB_SCRAPPER+`/download/${id}`, {
            responseType: 'blob', // Important: this ensures the response is treated as a file
        });

        // Create a URL for the file and trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        console.log("response.headers ::",response.headers);
        link.setAttribute('download',"file.csv")//response.headers['Content-Disposition'].split('filename=')[1]); // Extract filename
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading file:', error);
        alert('Failed to download file.'); // Handle error appropriately
    }
};

const handleUserInvite = useCallback((payload) => {
    axios.post(appUrl.WEB_SCRAPPER, {...payload}).then(response=>{
        console.log(response.data)
        setStartNew(false);
        dashboardApiCall()
    }).catch(err=>{
        showNotification("error", err.message || "Failed to create Web Scrapper")
    })
},[])

// Function to format the timestamp
const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { // Adjust options as needed
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};




    const columns = [
        {
            title: "S.NO.",
            dataIndex: "key",
            width: 100,
            key:"key",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Page Name",
            dataIndex: "pageName",
            key:"pageName",
        },

        {
            title: "Date",
            dataIndex: "createDate",
            key:"createDate",
            render: (_, record) => formatDate(record.createDate),
        },
        // {
        //     title: "Status",
        //     dataIndex: "active",
        //     width: 120,
        //     key:"active",
        //     // render: (_, record) => <Switch disabled={localStorage.getItem("email") !=="admin@procezo.com"} onChange={()=>handleUserUpdate(record)} checked={record.active} checkedChildren={<CheckOutlined style={{color: "#ffffff" }}/>} unCheckedChildren={<CloseOutlined />}
        //     // />,
        // },
        {
            title: "Action",
            dataIndex: "delete",
            width: 120,
            key:"delete",
            render: (_, record) => <DownloadOutlined onClick={()=> handleFileDownload(record._id)} style={{ fontSize: "18px", color: "#2a2a2a", cursor: "pointer" }} />,
        },

    ];


    return (
        <Row justify="space-between" className="listing-cb">
            <div style={{width:"100%", paddingBottom:"50px"}}>
                {localStorage.getItem("email") === "admin@procezo.com" && <Row className="mb20" justify="space-between" style={{"width":"100%", "marginTop":"30px"}}>
                <Col xs={24} sm={24} md={12}>
                    <Space align="center">
                        <Input
                        id="searchInput"
                        placeholder="Search..."
                        prefix={<SearchOutlined />}
                        size="large"

                        />
                        <MyTaskFilterDate
                        // handleChange={handleChange}
                        state={states}
                        // handleClickList={handleClickList}
                        />
                        <Button type="primary" size="large">
                        Submit
                        </Button>
                        <Button size="large" className="reset-btn">
                        Reset
                        </Button>
                    </Space>
                </Col>
                <Col xs={24} md={12} align="end">
                    <Space>
                        <Button type="primary" icon={<PlusCircleOutlined />} size="large" onClick={() => handleStartScrappingModal(true)}>{" "} Start New</Button>
                    </Space>
                </Col>
            </Row>}
            <Col span={24}>
                <Table columns={columns} dataSource={filteredData} pagination={false} bordered rowKey="_id" />
            </Col>

            <StartNewWebScrapper
                visible={showStartNew}
                handleModal={handleStartScrappingModal}
                onUserInvite={handleUserInvite}
                />
           </div>
        </Row>
    );
};

export default WebScrapper;
