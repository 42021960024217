import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Avatar, Col, Row, Tooltip, Card, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  getNameInitials,
  getAvatarColor,
  showNotification,
} from "../../utils/commonFunctions";
import constants from "../../utils/constants";
import envigoLogo from "../../images/logo.svg";
import userSquare from "../../images/user-square.svg";


const { URL_REGEX } = constants;
const { Meta } = Card;
const ChatItem = (props) => {
  const [paramsData, setParamsData] = useState("")
  const { message = {}, state } = props;
  const loggedInUser = useMemo(
    () => ({ _id: "123", name: "You", last_name: "" }),
    []
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(paramsData) {
      setParamsData(searchParams);
    }
  }, [paramsData, searchParams]);

  const { createdBy, body, canCopy, loading } = useMemo(() => {
    const createdBy = {};
    let canCopy = false;

    if (message?.role === "user") {
      createdBy._id = loggedInUser?._id;
      createdBy.name = `${loggedInUser?.name} ${
        loggedInUser?.last_name || ""
      }`.trim();
    } else if (message?.role === "assistant" || message?.role === "system") {
      canCopy = true;
      //createdBy._id = //"456"//state?.name;
      createdBy.name = state?.name;
    }

    return {
      createdBy,
      body: message?.content,
      canCopy,
      loading: message?.loading,
    };
  }, [message, loggedInUser]);

  const avatarColor = useMemo(
    () => getAvatarColor(createdBy?._id ?? 2),
    [createdBy?._id]
  );

  const nameInitials = useMemo(
    () => getNameInitials(createdBy?.name),
    [createdBy]
  );

  const renderText = useCallback(
    (txt) =>
      txt.split(" ").map((part, i) => {
        let text = part;
        text = URL_REGEX.test(part) ? (
          <a href={part} target="_blank" key={part} rel="noreferrer">
            {`${part} `}
          </a>
        ) : (
          `${part} `
        );

        return text;
      }),
    []
  );
  const onCopy = useCallback(() => { }, []);
  
console.log(
  nameInitials,
  "nameInitials"
);

  return (
    <Col className="open-ai-chat-item mb-2">
      <Row className="chat-item-container">
        <Col>
          {nameInitials === "Y" ? (
            <img src={userSquare} alt="User" style={{ width: "34px" }} />
          ) : (
            <img
              src={envigoLogo}
              alt="CitiusTech"
              style={{ width: "34px" }}
            />
          )}

          {/* <Avatar
              size={40}
              shape={"circle"}
              style={{ backgroundColor: avatarColor }}
            >
              {nameInitials || "B"}
            </Avatar> */}
        </Col>

        <Col className="chat-details-container">
          <Row className="user-details" align="middle" justify="space-between">
            {/* <span className="name">{createdBy.name ?? "Bot"}</span> */}
            <span className="name">
              {createdBy?.name || state?.name || "Bot"}
            </span>
            {/* <span>
              {!loading && canCopy && (
                <Tooltip title="Copy Text">
                  <CopyToClipboard
                    text={body}
                    onCopy={() =>
                      showNotification("success", "Copy to clipboard")
                    }
                  >
                    <CopyOutlined className="mr-1" onClick={onCopy} />
                  </CopyToClipboard>
                </Tooltip>
              )}
            </span> */}
          </Row>

          <Col className="chat-details">
            {typeof body === "string" ? (
              <Col className="message mt-2">{renderText(body)}</Col>
            ) : (
              body.map((o) => {
                return (
                  <Card
                    key={o["_id"]}
                    hoverable
                    style={{ width: "100%", marginTop: 10 }}
                    cover={
                      <img
                        alt="example"
                        style={{
                          height: "300px",
                          backgroundColor: "#6c6c6c",
                          objectFit: "contain",
                        }}
                        src={o.image_link}
                      />
                    }
                  >
                    <p>
                      <b>Name: </b>
                      {o["Product Name"]}
                    </p>
                    <p>
                      <b>Description: </b>
                      {o["Product Description"]}
                    </p>
                    <p>
                      <b>Size: </b>
                      {o["Size"]}
                    </p>
                    <p>
                      <b>Color: </b>
                      {o["Color"]}
                    </p>
                    <p>
                      <b>Price: </b>
                      {o["MRP"]}
                    </p>
                  </Card>
                );
              })
            )}
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(ChatItem);
